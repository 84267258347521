import React, { useState } from "react";
import { useSpring, animated, config } from "react-spring";

export default function DateTime() {
  const [flip, set] = useState(false);
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: false,
    // reverse: flip,
    delay: 200,
    config: config.molasses,
    onRest: () => set(!flip),
  });

  //   return <div className="text-center">time</div>;
  return (
    <animated.h1 className="text-center text-date" style={props}>
      10th May
      <p>
        <small>To infinity and beyond</small>
      </p>
    </animated.h1>
  );
}
