import React, { useState } from "react";

export default function Window01(props) {
  const { setWno } = props;
  const [hashText, setHashText] = useState("");
  const [error, setError] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    if (hashText !== "to_infinity" && hashText !== "#to_infinity") {
      setError(true);
      //   setWno(2);
    } else {
      console.log("pass");
      setError(false);
      setWno(2);
    }
  };

  return (
    <>
      <div className="w-full text-center" style={{ direction: "rtl" }}>
        <form
          onSubmit={onSubmit}
          autoComplete="off"
          className="flex flex-wrap justify-center">
          <input
            type={"text"}
            name="hastag"
            autoComplete="off"
            id="hastag-text"
            placeholder=""
            className="hash-input mb-2 md:order-2 md:mr-2  w-full md:w-1/2 "
            style={
              error
                ? {
                    borderColor: "red",
                  }
                : {}
            }
            value={hashText}
            onChange={(e) => setHashText(e.currentTarget.value)}
          />
          <button
            type="submit"
            style={{ direction: "rtl" }}
            className="hash-submit  md:order-1  w-full md:w-1/3  md:ml-2 ">
            جرب تكتب هنا ←
          </button>
        </form>
      </div>
      {error && (
        <div
          className="text-center flex justify-center text-gray-500 mt-3"
          style={{ direction: "rtl" }}>
          <div className="ml-3">كلمة السر:</div>
          <div style={{ direction: "ltr" }}>#to_infinity</div>
        </div>
      )}
    </>
  );
}
