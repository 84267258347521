import React, { useState } from 'react'
import DateTime from './dateTime'
import Loading from './Loading'
import Logo from './Logo'
import Window01 from './window01'

export default function App() {
  const [wno, setWno] = useState(1)
  return (
    <div className='  p-5  absolute  top-0 left-0 w-full h-full'>
      <div className='bg flex justify-center items-center flex-wrap w-full'>
        {/* <div className='w-full flex items-center justify-center h-fit mt-20' > */}
        {/* </div> */}

        <div className='w-full'>
          {/* <div class=""><canvas> </canvas></div> */}
          {wno === 1 && <Window01 setWno={setWno} />}
          {wno === 2 && <Loading setWno={setWno} />}
          {wno === 3 && <DateTime />}

        </div>
        <Logo />

      </div>
    </div>
  )
}
