import React from "react";
import logo from "./assets/R8.png";

export default function Logo() {
  return (
    <div className="absolute top-10">
      <img src={logo} className="w-56" alt="R8" />
    </div>
  );
}
